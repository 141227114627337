import React, { useState } from "react";
import { customerReviews } from "../Scripts/reviews";
import "../Styles/Reviews.css";

function Reviews() {
  let rMessage, rName, rLocation;
  const reviewsLength =
    customerReviews.length - 1;
  const [review, setReview] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState("");
  // back to previous review
  const backBtnClick = () => {
    setReview(
      review <= 0 ? reviewsLength : review - 1
    );
    handleReviewsUpdation();
  };

  // go to newer review
  const frontBtnClick = () => {
    setReview(
      review >= reviewsLength ? 0 : review + 1
    );
    handleReviewsUpdation();
  };

  // update reviews
  const handleReviewsUpdation = () => {
    const reviewMessage = customerReviews[review];
    rName = reviewMessage.name;
    rLocation = reviewMessage.location;
    rMessage = reviewMessage.message;
  };

  // list review on visit
  handleReviewsUpdation();

  const handleAddReview = () => {
    if (newReview.trim() !== "") {
      setReviews([...reviews, newReview]);
      setNewReview(""); // Clear the input field after adding the review
    }
  };

  return (
    <div className="review-section" id="reviews">
      <div className="rw-text-content">
        <h3>Reviews</h3>
        <p className="rw-text-format">
          <span className="rw-text-quote1">
            ''
          </span>
          <span className="rw-review">
            {rMessage}
          </span>
          <span className="rw-text-quote2">
            ''
          </span>
        </p>

        <div className="rw-authors">
          <div className="rw-names">
            <p className="rw-reviewer-name">
              {rName}
            </p>
            <p className="rw-reviewer-place">
              {rLocation}
            </p>
          </div>

          <div className="rw-btns">
            <button
              className="rw-next-btn"
              type="button"
              onClick={backBtnClick}
            >
              ←
            </button>
            <button
              className="rw-next-btn"
              type="button"
              onClick={frontBtnClick}
            >
              →
            </button>
          </div>
        </div>
        <div style={{ paddingTop: "30px" }}>
          <h4>Add a Review:</h4>
          <textarea
            value={newReview}
            onChange={(e) =>
              setNewReview(e.target.value)
            }
            placeholder="Enter your review here..."
            rows="4"
            cols="30"
          ></textarea>
          <br />
          <button onClick={handleAddReview}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
