import React from "react";
import Doctor from "../assets/why.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../Styles/BookAppointment.css";

const handleWhatsAppShare = () => {
  const message =
    "Hello Dr Fash, I'm reaching out because i need your assistance and guidance!";

  const whatsappURL = `https://wa.me/+256761444207?text=${encodeURIComponent(
    message
  )}`;
  window.open(whatsappURL, "_blank");
};


function BookAppointment() {
  const navigate = useNavigate();

  const handleBookAppointmentClick = () => {
    // navigate("/appointment");
  };

  return (
    <div className="ba-section">
      <div className="ba-image-content">
        <img
          src={Doctor}
          alt="Doctor Group"
          className="ba-image1"
        />
      </div>

      <div className="ba-text-content">
        <h3 className="ba-title">
          <span>Why Work with Me?</span>
        </h3>
        {/* <p className="ba-description">
          Dr. Fash is a , a
          revered African traditional healer and
          esteemed spiritual doctor. 
        </p> */}

        <p className="ba-checks ba-check-first">
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{ color: "#1E8FFD" }}
          />{" "}
          Satisfied customers globally
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{ color: "#1E8FFD" }}
          />{" "}
          Many services offered
        </p>
        <p className="ba-checks">
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{ color: "#1E8FFD" }}
          />{" "}
          24/7 Support
        </p>
        {/* <p className="ba-checks ba-check-last">
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{ color: "#1E8FFD" }}
          />{" "}
          Spiritual powers
        </p> */}

        <button
          className="text-appointment-btn"
          type="button"
          onClick={handleWhatsAppShare}
        >
          <FontAwesomeIcon
            icon={faCalendarCheck}
          />{" "}
          Book Appointment
        </button>
      </div>
    </div>
  );
}

export default BookAppointment;
