import React from "react";
import Doctor from "../assets/about1.png";
import "../Styles/About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";

const handleWhatsAppShare = () => {
  const message =
    "Hello Dr Fash, I'm reaching out because i need your assistance and guidance!";

  const whatsappURL = `https://wa.me/+256761444207?text=${encodeURIComponent(
    message
  )}`;
  window.open(whatsappURL, "_blank");
};

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img
          src={Doctor}
          alt="Doctor Group"
          className="about-image1"
        />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          {/* <span>Dr Fash</span> */}
        </h3>
        <p className="about-description">
          {/* Dr Fash is a spiritual doctor who
          specializes in shading more light on
          love spells explaining the good and bad
          side involved. */}
          Therapy provides a safe space for
          exploring and expressing emotions.
          Clients learn to identify and
          communicate their feelings effectively,
          fostering empathy and understanding
          within their relationships.
        </p>

        <button
          className="text-appointment-btn"
          type="button"
          onClick={handleWhatsAppShare}
        >
          <FontAwesomeIcon
            icon={faCalendarCheck}
          />{" "}
          Make an Appointment
        </button>
      </div>
    </div>
  );
}

export default About;
