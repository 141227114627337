import React from "react";
import DoctorCard from "./DoctorCard";
import profile1 from "../assets/lover.png";
import profile2 from "../assets/about.png";
import profile3 from "../assets/lostlover.png";
import profile4 from "../assets/court.png";
import profile5 from "../assets/healing.png";
import profile6 from "../assets/wrangles.png";
import profile7 from "../assets/attraction.jpg";
import profile8 from "../assets/marriage.png";
import profile9 from "../assets/business.png";
import profile10 from "../assets/devil.png";
import profile11 from "../assets/revenge.png";
import profile12 from "../assets/why.png";
import therapy1 from "../assets/therapy1.png";
import therapy2 from "../assets/therapy2.png";
import therapy3 from "../assets/therapy3.png";
import "../Styles/Doctors.css";

function Doctors() {
  return (
    <div className="doctor-section" id="services">
      <div className="dt-title-content">
        <h3 className="dt-title">
          {/* <span>Services</span> */}
          <span>What is Love Spell Therapy?</span>
        </h3>
      </div>

      <div className="dt-cards-content">
        <DoctorCard
          img={therapy1}
          name="Understanding Relationships"
          title="Love therapy helps individuals understand the dynamics of their relationships, including patterns of communication, conflict resolution, and intimacy"
        />
          <DoctorCard
          img={therapy1}
          name="Love Spells"
          title="Love spells are rituals or incantations performed with the intention of attracting love or influencing romantic feelings towards a specific person. "
        />
        <DoctorCard
          img={therapy2}
          name="Emotional Awareness"
          title="Therapy provides a safe space for exploring and expressing emotions. Clients learn to identify and communicate their feelings effectively, fostering empathy and understanding within their relationships."
        />
        <DoctorCard
          img={therapy3}
          name="Healing from Past Wounds"
          title="Many people carry emotional baggage from past experiences that affect their current relationships. Love therapy helps individuals process and heal from past traumas."
        />
        <DoctorCard
          img={therapy1}
          name="Improving Communication"
          title="Effective communication is essential for healthy relationships. Love therapy teaches clients active listening skills, assertive communication techniques."
        />
        <DoctorCard
          img={therapy2}
          name="Building Intimacy"
          title="Therapy helps couples deepen their emotional and physical intimacy by fostering vulnerability, trust, and connection. "
        />
      </div>
    </div>
  );
}

export default Doctors;
