import React from "react";
import "../Styles/Footer.css";
import { Link } from "react-router-dom";
import { GiPhone } from "react-icons/gi";
import {
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

const handleWhatsAppShare = () => {
  const message =
    "Hello Dr Fash, I'm reaching out because i need your assistance and guidance!";

  const whatsappURL = `https://wa.me/+256761444207?text=${encodeURIComponent(
    message
  )}`;
  window.open(whatsappURL, "_blank");
};

function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            {/* <p className="ft-title">
              Disclaimer:
              <span className="ft-sign"></span>
            </p> */}
            <p className="ft-description">
              As a seasoned love therapist and love spell caster, I
              specialize in guiding individuals
              and couples on their journey towards
              deeper connections, emotional
              healing, and relationship
              fulfillment. With years of
              experience in the field of
              psychology and counseling, I am
              passionate about helping people
              navigate the complexities of love
              and relationships.
            </p>
          </div>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">
            Emotional Awareness
          </p>
          <ul className="ft-list-items">
            {/* <li>
              <a href="#services">
                Win court cases
              </a>
            </li> */}
            <li>
              <a href="#services">Love Therapy</a>
            </li>
            {/* <li>
              <a href="#services">
                Cast out dark spells
              </a>
            </li> */}
            <li>
              <a href="#services">
                Couple Counselling
              </a>
            </li>
            <li>
              <a href="#services">
                Action Plan & Growth
              </a>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">
            Talk To Us
          </p>
          <ul className="ft-list-items">
            <li>
              <a href="mailto:drfash27@gmail.com">
                drfash27@gmail.com
              </a>
            </li>
            <li>
              <a href="tel:+256761444207">
                +256 761444207
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© Dr Fash 2024 All rights reserved.</p>

        <ul className="ft-social-links">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp
                onClick={handleWhatsAppShare}
              />
            </a>
          </li>

          <li>
            <a href="tel:+256761444207">
              <FaPhone />
            </a>
          </li>

          <li>
            <a href="mailto:drfash27@gmail.com">
              <AiOutlineMail size={35} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
