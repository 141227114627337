export const customerReviews = [
  {
    name: "Esther Howard",
    location: "Texas, USA",
    message:
      "I have not been a staunch believer in marriage and love therapy, but when you dig deeper and read more about love therapy, some how, it boosts your mood and confidence.",
  },
  // {
  //   name: "John Mike",
  //   location: "Ontario, Canada",
  //   message:
  //     "Facing legal issues that seemed insurmountable, I reached out to Dr Fash for his Win Court Cases rituals. Miraculously, the outcome turned in my favor, and justice prevailed. I am immensely grateful for his guidance and support.",
  // },
  {
    name: "Juma Mark",
    location: "Nairobi, Kenya",
    message:
      "Love therapy spells are not scientifically proven, but they do really boost your mood and confidence.",
  },
  // {
  //   name: "Bob John",
  //   location: "Ontario, Canada",
  //   message:
  //     "My business was on the verge of collapse, and I was losing hope. Dr Fash's Business Prosperity Enchantments turned my fortunes around. New opportunities flowed in, and my business is now thriving beyond expectations.",
  // },
  // {
  //   name: "Johnson Wayne",
  //   location: "Texas, USA",
  //   message:
  //     "Dr. Fash's knowledge of witchcraft is unparalleled. His compassionate approach and effective spells have helped me overcome challenges and manifest my deepest desires.",
  // },
  // {
  //   name: "Robert Wilson",
  //   location: "Kampala, UG",
  //   message:
  //     "The spell rituals I participated in only brought more chaos into my life, leaving me feeling deceived and helpless.",
  // },
  {
    name: "Mary Lee",
    location: "Nairobi, Kenya",
    message:
      "Desperate for love and companionship, love therapy was my last resort, will give a better review at the end of the road.",
  },
];
