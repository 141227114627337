import React, {
  useEffect,
  useState,
} from "react";
import about from "../assets/therapy1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { GiFireSpellCast } from "react-icons/gi";
import "../Styles/Hero.css";

function Hero() {
  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener(
      "scroll",
      onPageScroll
    );

    return () => {
      window.removeEventListener(
        "scroll",
        onPageScroll
      );
    };
  }, []);

  return (
    <div className="section-container">
      <div className="hero-section">
        <div className="text-section">
          <h2 className="text-title">
            Love Spell Therapy
            <GiFireSpellCast />
          </h2>
          <p className="text-descritpion">
            Love therapy helps individuals
            understand the dynamics of their
            relationships, including patterns of
            communication, conflict resolution,
            and intimacy.
          </p>
          <a href="tel:+256761444207">
            <button
              className="text-appointment-btn"
              type="button"
              // onClick={handleBookAppointmentClick}
            >
              <FontAwesomeIcon
                icon={faCalendarCheck}
              />
              Call Now
            </button>
          </a>
          {/* <div className="text-stats">
            <div className="text-stats-container">
              <p>5k+</p>
              <p>Clients</p>
            </div>
            <div className="text-stats-container">
              <p>10+</p>
              <p>Countries</p>
            </div>

            <div className="text-stats-container">
              <p>20+</p>
              <p>Years of Experience</p>
            </div>
          </div> */}
        </div>

        <div className="hero-image-section">
          <img
            className="hero-image1"
            src={about}
            alt="Doctor"
          />
        </div>
      </div>

      <div
        onClick={scrollToTop}
        className={`scroll-up ${
          goUp ? "show-scroll" : ""
        }`}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    </div>
  );
}

export default Hero;
